import classNames from "classnames"
import React, { useMemo } from "react"

import { useTranslation } from "@bounce/i18n"
import { Review } from "@bounce/web-components"

import type { Review as TReview } from "../../../../utils/reviews"

export type ReviewCardProps = {
  review: TReview
  withCity?: boolean
  className?: string
  avatarClassName?: string
}

export const ReviewCard = ({ review, withCity = false, className, avatarClassName = "" }: ReviewCardProps) => {
  const [t] = useTranslation()
  const name = review.user.fullName || t("cmp.reviewCardNext.anonymous", "Anonymous")

  const metaData = useMemo(() => {
    if (review.country) return `BOUNCED: ${review.country}`
    if (withCity) return `BOUNCED: ${review.city.translatedName}`
    return undefined
  }, [review, withCity])

  return (
    <Review
      className={classNames("rounded-lg border-2 border-neutral-300 px-5 py-4 xl:px-8 xl:py-6", className)}
      feedback={review.translatedFeedback ?? review.feedback}
      name={name}
      rating={review.rating}
      avatarSrc={review.user.image}
      avatarClassName={avatarClassName}
      metaData={metaData}
    />
  )
}
